import React from 'react';
import {twMerge} from 'tailwind-merge';
import {
  Image,
  ImageType,
  IMAGE_HEADER_HEIGHT,
  IMAGE_HEADER_WIDTH,
} from '~/features/shared';
import {Breadcrumb} from './Breadcrumb';

export interface ImageHeaderProps {
  image?: ImageType;
  title: string | React.ReactNode;
  breadcrumb: Array<{
    title: string | React.ReactNode;
    to?: string;
  }>;
  inverse?: boolean;
}

export function ImageHeader({
  image,
  title,
  breadcrumb,
  inverse,
}: ImageHeaderProps) {
  return (
    <section className="relative mb-32 w-full bg-gray-300 sm:mb-32 lg:rounded-3xl h-[200px] md:h-[350px]">
      <Image
        className="object-cover w-full h-full lg:rounded-3xl"
        image={image}
        loading="eager"
        height={IMAGE_HEADER_HEIGHT}
        width={IMAGE_HEADER_WIDTH}
      />

      <div
        className={twMerge(
          'bottom-[-110px] rounded-3xl absolute w-full py-8 px-5 sm:max-w-[400px] sm:left-10 sm:px-10 sm:bottom-[-4rem]',
          inverse ? 'bg-gray-100 sm:bg-white' : 'bg-white sm:bg-gray-100'
        )}
      >
        <h1 className="mb-3 text-4xl font-bold text-black">{title}</h1>

        <Breadcrumb items={breadcrumb} />
      </div>
    </section>
  );
}
